<template>
  <v-container
    fluid
    class="light-blue lighten-5 pb-16 travel-usa"
  >
    <v-overlay v-if="loading && !getPurchaseErrors">
      <v-row
        class="mt-12"
      >
        <v-col class="d-flex justify-center dummy">
          <ellipse-progress
            :loading="loading"
            :size="180"
            img-src="src/assets/Travel-Icon.png"
            thickness="5"
            empty-thickness="3"
            line-mode="out 5"
            legend="false"
            animation="rs 700 1000"
            font-size="1.5rem"
            color="#00A1B7"
            empty-color-fill="transparent"
            filename="Travel-Icon.png"
          >
          </ellipse-progress>
        </v-col>
      </v-row>
    </v-overlay>
    <v-dialog
      v-model="showErrorModal"
      max-width="700"
    >
      <v-card min-height="33vh">
        <v-card-title class="justify-center font-weight-bold text-h5">
        </v-card-title>
        <v-card-text class="justify-center text-center">
          <v-row>
            <v-col>
              Sorry, something went wrong. {{ getPurchaseErrors }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-row no-gutters>
          <v-col>
            <div class="d-flex justify-center">
              <v-btn
                color="primary"
                class="mb-4 mt-4 font-weight-bold"
                @click="clearErrors"
              >
                Close
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <!--------------------------------------STEPPER-------------------------->
    <v-row class="mb-0 d-flex justify-center align-center">
      <v-col
        cols="12"
        sm="11"
        md="9"
        class="pb-0 mb-0"
      >
        <v-card
          elevation="0"
          class="white px-auto"
        >
          <v-stepper>
            <v-stepper-header>
              <v-stepper-step
                step="1"
                complete
                complete-icon="mdi-airplane"
              >
                TRIP INFO
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                step="2"
                complete
                complete-icon="mdi-airplane"
              >
                CHOOSE PLAN
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                step="3"
                complete
                complete-icon="mdi-airplane"
              >
                PURCHASE PLAN
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="4">
                DOWNLOAD YOUR POLICY
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </v-card>
      </v-col>
    </v-row>

    <!-- LOGIN -->
    <div v-if="!userpresent">
      <v-row class="justify-center">
        <v-col
          cols="12"
          sm="11"
          md="9"
        >
          <v-card>
            <v-card-text>
              <v-row class="d-flex justify-center">
                <v-col
                  class="d-flex justify-center text-h3 text-center primary--text mb-6 mt-6"
                >
                  Log In to your Account
                </v-col>
              </v-row>
              <v-row>
                {{ errorMessage }}
              </v-row>
              <v-row>
                <v-col>
                  <v-form ref="form">
                    <validation-observer
                      ref="observer"
                      v-slot="{ invalid }"
                    >
                      <v-row class="d-flex justify-center">
                        <v-col class="pb-0">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Email"
                            rules="required|email"
                          >
                            <v-text-field
                              v-model="mailingEmail"
                              label="Email"
                              outlined
                              append-icon="mdi-email-outline"
                              :error-messages="errors"
                              @click="
                                clickedFieldGlobal(
                                  'email',
                                  66,
                                  'American Travel Survey v1'
                                )
                              "
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-center">
                        <v-col class="pb-0">
                          <validation-provider
                            v-slot="{ errors }"
                            vid="password"
                            name="Password"
                            rules="required"
                          >
                            <v-text-field
                              ref="password"
                              v-model="password"
                              name="password"
                              label="Password"
                              outlined
                              :type="show ? 'text' : 'password'"
                              :append-icon="
                                show ? 'mdi-eye-outline' : 'mdi-eye-off'
                              "
                              :error-messages="errors"
                              @click:append="
                                show = !show;
                                clickedFieldGlobal(
                                  'password',
                                  67,
                                  'American Travel Survey v1'
                                );
                              "
                            >
                            </v-text-field>
                          </validation-provider>
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-center">
                        <v-col
                          cols="6"
                          class="d-flex justify-center"
                        >
                          <v-btn
                            class="primary whtie--text mr-0"
                            block
                            large
                            :disabled="invalid"
                            @keyup.enter="submit"
                            @click="
                              loginSubmit();
                              clickedFieldGlobal(
                                'logIn',
                                68,
                                'American Travel Survey v1'
                              );
                            "
                          >
                            <span class="login-btn">Log In</span>
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-center">
                        <v-col>
                          <div class="signup-text text-center">
                            <span
                              style="color: #757575"
                            >Don't have an account?</span>
                            <!-- <button
                              text
                              class="primary--text font-weight-bold"
                              @click="
                                createAccount();
                                clickedFieldGlobal(
                                  'createAccount',
                                  69,
                                  'American Travel Survey v1'
                                );
                              "
                            >
                              Create One For Me using: {{ mailingEmail }}
                            </button> -->
                            <create-account-button
                              :button-text="'Create One For Me using: ' + mailingEmail"
                              :button-classes="['primary--text','font-weight-bold','theme--light','v-btn--text','v-btn']"
                              :large="false"
                              :block="false"
                              :email="mailingEmail"
                              :password="password"
                              :post-signup-callback="postSignup"
                              :text="true"
                              :is-form-invalid="invalid"
                            />
                          </div>
                        </v-col>
                      </v-row>
                    </validation-observer>
                  </v-form>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <!------------------------------------------------FORM---------------------------------->
    <v-row
      v-if="userpresent"
      class="d-flex justify-center pb-16"
    >
      <v-col
        cols="12"
        sm="11"
        md="9"
      >
        <v-card>
          <validation-observer ref="observer">
            <v-form
              ref="form"
              class="px-8 font-weight-bold"
              @submit.prevent="submit"
            >
              <v-row>
                <v-col class="top-row">
                  <div class="title-text text-center mt-2">
                    Pay And Download Policy
                  </div>
                </v-col>
              </v-row>
              <!---------------------PRIMARY TRAVELER----------------------->
              <v-row>
                <v-col>
                  <div class="sub-title mt-2">
                    Primary Traveler
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="8"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="First Name"
                    rules="alpha|max:15|required"
                  >
                    <v-text-field
                      v-model="travelersFirstName[0]"
                      outlined
                      label="First Name"
                      :error-messages="errors"
                      @click="
                        clickedFieldGlobal(
                          'firstName',
                          70,
                          'American Travel Survey v1'
                        )
                      "
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="8"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Last Name"
                    rules="alpha_spaces|max:20|required"
                  >
                    <v-text-field
                      v-model="travelersLastName[0]"
                      outlined
                      label="Last Name"
                      :error-messages="errors"
                      @click="
                        clickedFieldGlobal(
                          'lastName',
                          71,
                          'American Travel Survey v1'
                        )
                      "
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <vuetify-google-autocomplete
                    id="map"
                    ref="address"
                    v-model="autoComplete"
                    classname="form-control"
                    outlined
                    placeholder="Please type your address"
                    country="us"
                    @placechanged="getMailAddressData"
                    @click="
                      clickedFieldGlobal(
                        'mapAutoComplete',
                        72,
                        'American Travel Survey v1'
                      )
                    "
                  >
                  </vuetify-google-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Address"
                    rules="required"
                  >
                    <v-text-field
                      v-model="mailingAddress"
                      outlined
                      :error-messages="errors"
                      label="Address"
                      @click="
                        clickedFieldGlobal(
                          'address',
                          73,
                          'American Travel Survey v1'
                        )
                      "
                    >
                    </v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="8"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="City"
                    rules="required"
                  >
                    <v-text-field
                      v-model="mailingCity"
                      outlined
                      :error-messages="errors"
                      label="City"
                      @click="
                        clickedFieldGlobal(
                          'city',
                          74,
                          'American Travel Survey v1'
                        )
                      "
                    >
                    </v-text-field>
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="8"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="State"
                    rules="required"
                  >
                    <v-text-field
                      v-model="mailingState"
                      outlined
                      :error-messages="errors"
                      label="State"
                      @click="
                        clickedFieldGlobal(
                          'state',
                          75,
                          'American Travel Survey v1'
                        )
                      "
                    >
                    </v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="8"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Zip Code"
                    rules="required|numeric"
                  >
                    <v-text-field
                      v-model="mailingZip"
                      outlined
                      :error-messages="errors"
                      label="Zip Code"
                      @change="getpostcodedata"
                      @click="
                        clickedFieldGlobal(
                          'zipCode',
                          76,
                          'American Travel Survey v1'
                        )
                      "
                    >
                    </v-text-field>
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="8"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Phone"
                    rules="required|digits:10"
                  >
                    <v-text-field
                      v-model="mailingPhone"
                      v-mask="'##########'"
                      outlined
                      :error-messages="errors"
                      label="Phone Number"
                      @click="
                        clickedFieldGlobal(
                          'phoneNumber',
                          77,
                          'American Travel Survey v1'
                        )
                      "
                    >
                    </v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <v-text-field
                      v-model="mailingEmail"
                      outlined
                      :error-messages="errors"
                      label="Email"
                      @click="
                        clickedFieldGlobal(
                          'mailingEmail',
                          78,
                          'American Travel Survey v1'
                        )
                      "
                    >
                    </v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <div v-if="numInsured > 1">
                <div
                  v-for="n in coApplicants"
                  :key="n"
                >
                  <v-row>
                    <v-col>
                      <div class="sub-title mt-2">
                        Traveler #{{ n + 1 }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="8"
                      md="6"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="First Name"
                        rules="alpha|required"
                      >
                        <v-text-field
                          v-model="travelersFirstName[n]"
                          outlined
                          label="First Name"
                          :error-messages="errors"
                          @click="
                            clickedFieldGlobal(
                              'firstName',
                              79,
                              'American Travel Survey v1'
                            )
                          "
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="8"
                      md="6"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Last Name"
                        :rules="{ required: true, regex: /^[a-zA-Z\-]+$/ }"
                      >
                        <v-text-field
                          v-model="travelersLastName[n]"
                          outlined
                          label="Last Name"
                          :error-messages="errors"
                          @click="
                            clickedFieldGlobal(
                              'lastName',
                              80,
                              'American Travel Survey v1'
                            )
                          "
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <v-row>
                <v-col>
                  <div>
                    How should we deliver your Description of Coverage/Policy?
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn-toggle
                    v-model="emailOrPostal"
                    class="mb-4"
                    mandatory
                  >
                    <v-btn
                      color="blanketbutton"
                      text
                      outlined
                      value="email"
                      @click="
                        clickedFieldGlobal(
                          'email',
                          81,
                          'American Travel Survey v1'
                        )
                      "
                    >
                      Email
                    </v-btn>

                    <v-btn
                      color="blanketbutton"
                      text
                      outlined
                      value="postal"
                      @click="
                        clickedFieldGlobal(
                          'postal',
                          82,
                          'American Travel Survey v1'
                        )
                      "
                    >
                      Postal Service
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="form-title text-center mt-2">
                    Payment Details
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-checkbox
                    v-model="checkbox"
                    label="Billing Info Same as Mailing Info"
                    @click="
                      clickedFieldGlobal(
                        'billingInfoChckbx',
                        83,
                        'American Travel Survey v1'
                      )
                    "
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
              <!----------------------Billing Address--------------------->
              <div v-if="!checkbox">
                <v-row>
                  <v-col>
                    <h1 class="primary--text mt-2">
                      Billing Information
                    </h1>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="8"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="First Name"
                      rules="alpha|required"
                    >
                      <v-text-field
                        v-model="billingFirstName"
                        outlined
                        label="First Name"
                        :error-messages="errors"
                        @click="
                          clickedFieldGlobal(
                            'billingFirstName',
                            84,
                            'American Travel Survey v1'
                          )
                        "
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="8"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Last Name"
                      :rules="{ required: true, regex: /^[a-zA-Z\-]+$/ }"
                    >
                      <v-text-field
                        v-model="billingLastName"
                        outlined
                        label="Last Name"
                        :error-messages="errors"
                        @click="
                          clickedFieldGlobal(
                            'billingLastName',
                            85,
                            'American Travel Survey v1'
                          )
                        "
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <vuetify-google-autocomplete
                      id="map2"
                      ref="billingAddress"
                      classname="form-control"
                      outlined
                      placeholder="Please type your address"
                      country="us"
                      @placechanged="getBillingAddressData"
                      @click="
                        clickedFieldGlobal(
                          'billingMapAutoComplete',
                          86,
                          'American Travel Survey v1'
                        )
                      "
                    >
                    </vuetify-google-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Address"
                      rules="required"
                    >
                      <v-text-field
                        v-model="billingAddress"
                        outlined
                        :error-messages="errors"
                        label="Address"
                        @click="
                          clickedFieldGlobal(
                            'billingAddress',
                            87,
                            'American Travel Survey v1'
                          )
                        "
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="8"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="City"
                      rules="required"
                    >
                      <v-text-field
                        v-model="billingCity"
                        outlined
                        :error-messages="errors"
                        label="City"
                        @click="
                          clickedFieldGlobal(
                            'billingCity',
                            88,
                            'American Travel Survey v1'
                          )
                        "
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="8"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="State"
                      rules="required"
                    >
                      <v-text-field
                        v-model="billingState"
                        outlined
                        :error-messages="errors"
                        label="State"
                        @click="
                          clickedFieldGlobal(
                            'billingState',
                            89,
                            'American Travel Survey v1'
                          )
                        "
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="8"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Zip Code"
                      rules="required|numeric"
                    >
                      <v-text-field
                        v-model="billingZip"
                        outlined
                        :error-messages="errors"
                        label="Zip Code"
                        @click="
                          clickedFieldGlobal(
                            'billingZipCode',
                            90,
                            'American Travel Survey v1'
                          )
                        "
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="8"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Phone"
                      rules="digits:10"
                    >
                      <v-text-field
                        v-model="billingPhone"
                        v-mask="'##########'"
                        outlined
                        :error-messages="errors"
                        label="Phone Number"
                        hint="By providing your phone number, you may receive calls or texts about your policy and claims."
                        persistent-hint
                        @click="
                          clickedFieldGlobal(
                            'billingPhone',
                            91,
                            'American Travel Survey v1'
                          )
                        "
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Email"
                      rules="required|email"
                    >
                      <v-text-field
                        v-model="billingEmail"
                        outlined
                        :error-messages="errors"
                        label="Email"
                        @click="
                          clickedFieldGlobal(
                            'billingEmail',
                            92,
                            'American Travel Survey v1'
                          )
                        "
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
              </div>
              <v-row class="justify-center">
                <v-col
                  cols="12"
                  sm="8"
                  md="10"
                >
                  <v-card class="pa-4 mt-0">
                    <v-card-text class="primary--text font-weight-bold pa-0">
                      Carrier: {{ coverageDetails.carrier.toUpperCase() }}
                      <v-icon
                        right
                        large
                        color="success"
                        class="mb-4 check"
                      >
                        mdi-check-bold
                      </v-icon>
                    </v-card-text>
                    <v-card-text class="primary--text font-weight-bold pa-0">
                      Plan: {{ coverageDetails.plan.toUpperCase() }}
                      <v-icon
                        right
                        large
                        color="success"
                        class="mb-4 check"
                      >
                        mdi-check-bold
                      </v-icon>
                    </v-card-text>
                    <!-- <v-card-text class="primary--text font-weight-bold pa-0">
                      Optional Coverages: {{ optionalCoverages }}
                      <v-icon
                        right
                        large
                        color="success"
                        class="mb-4 check"
                      >
                        mdi-check-bold
                      </v-icon>
                    </v-card-text> -->
                    <v-card-text class="primary--text font-weight-bold pa-0">
                      Cost: ${{ formattedPrice }}
                      <v-icon
                        right
                        large
                        color="success"
                        class="mb-4 check"
                      >
                        mdi-check-bold
                      </v-icon>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <div v-if="userpresent">
                <v-row>
                  <v-col>
                    <h1 class="primary--text mt-2">
                      Credit Card Information
                    </h1>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="8"
                    md="10"
                  >
                    Card Type
                    <v-radio-group
                      v-model="CardType"
                      mandatory
                      row
                    >
                      <v-radio
                        v-for="card in paymenttypes"
                        :key="card.code"
                        :label="card.cardname"
                        :value="card.code"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="8"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Cardholder Name"
                      :rules="{ required: true, regex: /^[a-zA-Z \-]+$/ }"
                    >
                      <v-text-field
                        v-model="cardholderName"
                        outlined
                        label="Cardholder Name"
                        :error-messages="errors"
                        @click="
                          clickedFieldGlobal(
                            'cardholderName',
                            93,
                            'American Travel Survey v1'
                          )
                        "
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="8"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Card Number"
                      :rules="`min:15|max:16|required|credit_card:${this.CardType}`"
                    >
                      <v-text-field
                        v-model="cardNumber"
                        v-mask="'################'"
                        outlined
                        label="Card Number (no spaces)"
                        :error-messages="errors"
                        @click="
                          clickedFieldGlobal(
                            'cardNumber',
                            94,
                            'American Travel Survey v1'
                          )
                        "
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="8"
                    md="4"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Expiration Month"
                      rules="digits:2|min_value:01|max_value:12|required"
                    >
                      <v-text-field
                        v-model="expMonth"
                        v-mask="'##'"
                        outlined
                        label="Exp Month (MM)"
                        :error-messages="errors"
                        @click="
                          clickedFieldGlobal(
                            'expMonth',
                            95,
                            'American Travel Survey v1'
                          )
                        "
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="8"
                    md="4"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Expiry Year"
                      rules="required"
                    >
                      <v-text-field
                        v-model="expYear"
                        v-mask="'##'"
                        outlined
                        label="Exp Year (YY)"
                        :error-messages="errors"
                        @click="
                          clickedFieldGlobal(
                            'expYear',
                            96,
                            'American Travel Survey v1'
                          )
                        "
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="8"
                    md="4"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Security Code"
                      rules="min:3|max:4|numeric|required"
                    >
                      <v-text-field
                        v-model="cvv"
                        outlined
                        label="Security Code CVV"
                        :error-messages="errors"
                        @click="
                          clickedFieldGlobal(
                            'cvv',
                            97,
                            'American Travel Survey v1'
                          )
                        "
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
              </div>
              <v-dialog
                v-model="stateError"
                transition="dialog-bottom-transition"
                max-width="600"
                persistent
              >
                <v-card>
                  <v-toolbar
                    color="primary"
                    dark
                  >
                    Error
                  </v-toolbar>
                  <v-card-text>
                    <div class="text-h2 pa-12">
                      State must match the state entered in travel information.
                    </div>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn
                      text
                      @click="
                        clearForm();
                        stateError = false;
                        clickedFieldGlobal(
                          'closeStateError',
                          98,
                          'American Travel Survey v1'
                        );
                      "
                    >
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-row>
                <v-col>
                  <div class="d-flex justify-center text-center">
                    <v-btn
                      large
                      color="primary"
                      class="mb-2 font-weight-bold"
                      :disabled="!userpresent || !creditcardvalid"
                      @click="
                        submit();
                        clickedFieldGlobal(
                          'purchasePolicy',
                          99,
                          'American Travel Survey v1'
                        );
                      "
                    >
                      Purchase Policy
                    </v-btn>
                  </div>
                  <div class="d-flex justify-center text-center">
                    <v-btn
                      class="font-weight-bold"
                      color="primary"
                      @click="
                        $router.go(-1),
                        clickedFieldGlobal(
                          'goBack',
                          100,
                          'American Travel Survey v1'
                        )
                      "
                    >
                      <v-icon left>
                        mdi-arrow-left-bold
                      </v-icon>
                      Go Back
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </validation-observer>
        </v-card>
      </v-col>
    </v-row>
    <otp-verification
      path="src/assets/images/Travel-Icon.png"
      filename="Travel-Icon.png"
    />
  </v-container>
</template>

<script>
  import moment from 'moment'
  import CreateAccountButton from '../../../components/base/CreateAccountBtn'
  import idleMixin from '../../../mixins/IdleMixin'
  const zipcodes = require('zipcodes')
  var numeral = require('numeral')
  const m = moment()
  export default {
    components: {
      CreateAccountButton
    },
    mixins: [idleMixin],
    data () {
      return {
        paymenttypes: [
          { cardname: 'Visa or Master Card', code: 'Vi' },
          { cardname: 'Discover', code: 'Di' },
          { cardname: 'American Express', code: 'AM' }
        ],
        showErrorModal: false,
        CardType: { cardname: 'Visa or Master Card', code: 'Vi' },
        validUser: false,
        password: '',
        show: '',
        radioGroup: '',
        autoComplete: '',
        stateError: false,
        travelersFirstName: [],
        travelersLastName: [],
        emailOrPostal: '',
        checkbox: true,
        mailingFirstName: '',
        mailingLastName: '',
        mailingEmail: '',
        mailingAddress: '',
        mailingSuite: '',
        mailingCity: '',
        mailingState: '',
        mailingZip: '',
        mailingPhone: '',
        billingFirstName: '',
        billingLastName: '',
        billingEmail: '',
        billingAddress: '',
        billingSuite: '',
        billingCity: '',
        billingState: '',
        billingZip: '',
        billingPhone: '',
        cardholderName: '',
        expMonth: '',
        expYear: '',
        cardNumber: '',
        cvv: '',
        standard: '',
        preferred: '',
        premium: '',
        getUserQuote: this.$store.getters.getUSATravelQuote,
        partnerId: null,
        campaignId: null
      }
    },
    computed: {
      errorMessage () {
        let message = ''
        message = this.$store.getters.getErrors
        return message
      },
      creditcardvalid () {
        // console.log('radioGroup', this.radioGroup)
        let valid = true
        if (
          !this.cardholderName ||
          !this.expMonth ||
          !this.expYear ||
          !this.cardNumber ||
          !this.cvv
        ) {
          valid = false
        }
        return valid
      },
      userpresent () {
        return this.$store.getters.getuser !== null
      },
      formattedPrice () {
        return numeral(this.coverageDetails.price).format('0.00')
      },
      finalPrice () {
        return this.coverageDetails.price
      },
      coverageDetails () {
        return this.$store.getters.getUSATravelDetails
      },
      numInsured () {
        return this.coverageDetails.numinsured
      },
      coApplicants () {
        if (this.numInsured > 1) {
          return this.numInsured - 1
        }
        return 0
      },
      ages () {
        return this.coverageDetails.age
      },
      getError () {
        return this?.$store?.getters?.getPurchaseErrors
      },
      getPurchaseErrors () {
        let message = ''
        if (this?.$store?.getters?.getPurchaseErrors?.[0]) {
          this.$store.getters.getPurchaseErrors.forEach(entry => {
            message += entry + ' \n'
          })
        }
        if (message) {
          this.showErrorModal = true
        } else {
          this.showErrorModal = false
        }
        return message
      },
      loading () {
        return this.$store.getters.getloading
      },
      isPolicyPurchased () {
        return this.$store.getters.getIsPolicyPurchased
      },
      partnerID () {
        return this.$store.getters.getPartnerId
      },
      campaignID () {
        return this.$store.getters.getCampaignId
      }
    },
    watch: {
      isPolicyPurchased (val) {
        if (val) {
          this.$router.push({ path: '/travel/policyusa' })
        }
      },
      getError (val) {
        if (!val) {
          this.$store.commit('setLoading', false)
          this.$forceUpdate()
        }
      }
    },
    mounted () {
      if (!this.$store.getters.getUSATravelQuote) {
        this.$router.push({ path: '/travel/infousa' })
      }
      this.getQuote = this.$store.getters.getUSATravelQuote
    },
    methods: {
      clearErrors () {
        this.showErrorModal = false
      },
      getpostcodedata (postcode) {
        console.log('looking zip code data ')
        let loc = zipcodes.lookup(postcode)
        console.log('zip code data ', loc)
        if (loc.country === 'US') {
          this.CountryCode = 'USA'
        }
        this.mailingState = loc.state
        console.log('setting this.StateOrProvinceCode ', this.mailingState)
      // this.$store.dispatch('checkpostcode', postcode)
      // console.log(this.$store.getters.postcodedata)
      },
      loginSubmit () {
        // log user in
        console.log('login with ', this.mailingEmail, this.password)
        this.$store.dispatch('signUserIn', {
          email: this.mailingEmail,
          password: this.password
        })
      },
      createAccount () {
        // put account creation function here
        this.$store.dispatch('createUserAndProfileForUser', {
          email: this.mailingEmail
        })
      },
      clearForm () {
        this.mailingAddress = ''
        this.mailingCity = ''
        this.mailingZip = ''
        this.mailingState = ''
        this.autoComplete = ''
      },
      async submit () {
        let complete = await this.$refs.observer.validate()
        if (!complete) {
          console.log('error submitting form')
        } else if (!this.coverageDetails.options) {
          if (!this.cvv) {
            console.log('how did we advance with no cc info?')
            return
          }
          let coveragedetails = this.$store.getters.getUSATravelDetails
          console.log('COVERAGE DETAILS', coveragedetails)
          let policy = {
            price: this.finalPrice,
            created: Date.now(),
            type: 'TRAVEL',
            carrierRef: '99999',
            emailaddress: this.mailingEmail,
            productclass: coveragedetails.productclass,
            numinsured: coveragedetails.numinsured,
            departdate: coveragedetails.departdate,
            returndate: coveragedetails.returndate,
            triptype: coveragedetails.triptype,
            destination: coveragedetails.destination,
            tripcost: coveragedetails.tripcost,
            travelerfirstname: this.travelersFirstName,
            travelerlastname: this.travelersLastName,
            age: coveragedetails.age,
            address1: this.mailingAddress,
            city: this.mailingCity,
            state: this.mailingState,
            zipcode: this.mailingZip,
            telephonehome: this.mailingPhone,
            paymentmethod: this.CardType,
            ccorcheckno: this.cardNumber,
            ccexpiration: this.expMonth + this.expYear,
            ccname: this.cardholderName,
            cvv: this.cvv,
            // cvv: this.cvv,
            cczipcode: this.mailingZip,
            partnerId: this.partnerID,
            campaignId: this.campaignID
          }
          // paymentmethod: 'Vi',
          // ccorcheckno: this.cardNumber,
          // ccexpiration: this.expMonth + this.expYear,
          // ccname: this.cardholderName,
          // cczipcode: '921236789'
          console.log('buying from ui part 1', policy)
          console.log(
            'coverage details are ',
            this.$store.getters.getUSATravelDetails
          )
          this.$store.dispatch('purchaseTravelQuoteUSA', policy)
        } else {
          console.log('sending from else ')
          let coveragedetails = this.$store.getters.getUSATravelDetails
          console.log('COVERAGE DETAILS', coveragedetails)
          let policy = {
            price: this.finalPrice,
            created: Date.now(),
            type: 'TRAVEL',
            carrierRef: '99999',
            emailaddress: this.mailingEmail,
            productclass: coveragedetails.productclass,
            numinsured: coveragedetails.numinsured,
            departdate: coveragedetails.departdate,
            returndate: coveragedetails.returndate,
            triptype: coveragedetails.triptype,
            destination: coveragedetails.destination,
            tripcost: coveragedetails.tripcost,
            travelerfirstname: this.travelersFirstName,
            travelerlastname: this.travelersLastName,
            age: coveragedetails.age,
            address1: this.mailingAddress,
            city: this.mailingCity,
            state: this.mailingState,
            zipcode: this.mailingZip,
            telephonehome: this.mailingPhone,
            paymentmethod: 'Vi',
            ccorcheckno: this.cardNumber,
            ccexpiration: this.expMonth + this.expYear,
            ccname: this.cardholderName,
            // cvv: this.cvv,
            cczipcode: this.mailingZip,
            options: this.coverageDetails.options,
            partnerId: this.partnerID,
            campaignId: this.campaignID
          }
          console.log('buying from ui ', policy)
          console.log(
            'coverage details are ',
            this.$store.getters.getUSATravelDetails
          )
          this.$store.dispatch('purchaseTravelQuoteUSA', policy)
        }
      },
      created () {
        this.coverageDetails = this.$store.getters.getUSATravelDetails
        console.log(
          'created details are ',
          this.$store.getters.getUSATravelDetails
        )
      },
      goBack () {
        this.$gtag.event('TravelPay', { action: 'goBackToSelectPlan' })
        this.$router.push({ path: '/travel/selectplanusa' })
      },
      getMailAddressData (addressData, placeResultData, id) {
        this.mailingAddress = addressData.name
        this.mailingCity = addressData.locality
        this.mailingZip = addressData.postal_code
        this.mailingState = addressData.administrative_area_level_1
        if (
          this.mailingState &&
          this.mailingState !== this.coverageDetails.living
        ) {
          this.stateError = true
        } else {
          this.stateError = false
        }
      },
      getBillingAddressData (addressData, placeResultData, id) {
        this.billingAddress = addressData.name
        this.billingCity = addressData.locality
        this.billingZip = addressData.postal_code
        this.billingState = addressData.administrative_area_level_1
      },
      clickedField (value, dataPoint) {
        this.$gtag.event('American Travel Survey v1', {
          userId: `${this.$store.getters.getuser.id}`,
          dataPoint,
          action: 'Clicked field',
          data: `${value}`
        })
      },
      postSignup () {
        this.clickedFieldGlobal(
          'createAccount',
          69,
          'American Travel Survey v1'
        )
      }
    }
  }
</script>

<style lang="scss" scoped>
.title-text {
  font-size: 36px;
  font-weight: 400;
  color: #00a1b7;
}

.container {
  background-color: #d8fdd7;
}

.sub-title {
  font-size: 20px;
  font-weight: 700;
  color: #00a1b7;
}

.form-title {
  color: #00a1b7;
  font-size: 36px;
  font-weight: 400;
}

.top-row {
  position: relative;
}

.back-btn {
  position: absolute;
  right: 0;
  top: 2rem;
}

</style>
<style lang="scss">
.travel-usa label {
  margin-bottom: 0px !important;
}
</style>
